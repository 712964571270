<template>
  <div>
    <v-list>
      <v-list-group
        v-for="item in itemsList"
        :key="item.title"
        v-model="item.active"
        :prepend-icon="item.iconGroup"
        @click="_getMenuItem()"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="item.namegroup"></v-list-item-title>
          </v-list-item-content>
        </template>
        <div v-if="loadingMenu" style="text-align: center; padding-top: 20px">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>

        <v-container v-if="!$store.state.dataMenu" style="height: 400px">
          <v-row class="fill-height" align-content="center" justify="center">
            <v-col class="text-subtitle-1 text-center" cols="12">
              Cargando menú
            </v-col>
            <v-col cols="6">
              <v-progress-linear
                color="deep-purple accent-4"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </v-container>

        <v-list-item
          @click.native="_nextView(child.route, child.id_role, child.name)"
          to=""
          link
          v-for="child in itemsMenu"
          :key="child.name"
        >
          <v-list-item-icon v-if="$store.state.dataMenu">
            <v-icon v-text="child.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content v-if="$store.state.dataMenu">
            <v-list-item-title v-text="child.name"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-list-item @click="cerrarSesion" link class="btn__logout">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Salir del sistema</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "listMenu",
  data: () => ({
    loadingMenu: false,
  }),
  mounted() {
    this._getGroupList();
    this._getMenuItem();

    // this._getMenuItem(this.itemsList[0].id);
  },
  computed: {
    ...mapState(["itemsList", "itemsMenu"]),
  },
  methods: {
    ...mapActions(["_getMenuItem", "_getGroupList", "_getEntities"]),
    _nextView(name, id_role, namemodule) {
      this.$store.state.dataList = false;

      this._getEntities(id_role);
      this.$store.state.menuName = namemodule;
      this.$router.push({ name: name, params: { id_role } }).catch(() => {});
    },
    cerrarSesion() {
      // localStorage.setItem("branch"
      localStorage.removeItem("branch");
      localStorage.removeItem("token");
      localStorage.removeItem("datos");
      this.$router.push({ name: "login" });
    },
  },
  beforeMount() {
    this._getMenuItem();
  },
};
</script>

<style lang="scss" scoped>
.type__btn {
  cursor: pointer !important;
}

.btn__logout {
  position: absolute;
  bottom: 0px;
  background: #252c32;
  color: white;
  margin-left: 60px;
  width: 240px;
}
</style>
